import { Component, Ref, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import BadgesMixin from '@/modules/level-two/views/event/modules/badges/mixins/badges.mixin'
import { mapGetters, mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { BadgesSetting } from '@/modules/level-two/models/badges.model'

@Component({
  name: 'GtrBadgesSettingsView',
  computed: {
    ...mapState('badges', ['badges', 'settings']),
    ...mapGetters('media', ['defaultFonts', 'fontList']),
    ...mapState('email', ['emails'])
  }
})
export default class GtrBadgesSettingsView extends mixins(GtrSuper, BadgesMixin) {
  settings!: BadgesSetting[]
  badges!: Array<Record<string, any>>
  defaultFonts!: Array<Record<string, any>>;
  fontList!: any[]
  kioskColumnWidth = 6
  kioskColumnWidthLg = 8

  @Ref('kioskPreviewContainer') kioskPreviewContainer!: HTMLElement
  @Ref('ipadFrame') ipadFrame!: HTMLElement

  toggleKioskColumnWidth () {
    this.kioskColumnWidth = this.kioskColumnWidth === 6 ? 12 : 6
    this.kioskColumnWidthLg = this.kioskColumnWidthLg === 8 ? 12 : 8
  }

  get showSettingsColumn () {
    return this.kioskColumnWidth === 12
  }

  get globalSettings () {
    return { 'Global Settings': this.$data.settingsForm['Global Settings'] }
  }

  get kioskPreviewExpandButtonText () {
    return this.kioskColumnWidth === 6 ? 'Full Sized View' : 'Compact View'
  }

  get pageSettings () {
    const pageSettings = {}
    for (const page in this.$data.settingsForm) {
      if (page !== 'Global Settings') {
        pageSettings[page] = this.$data.settingsForm[page]
      }
    }
    return pageSettings
  }

  get enableBadgeFieldEditing () {
    return this.previewPageSettings['Page 3'].enable_font_size_adjustment_page3 === '1'
  }

  get badgePreviewStyle () {
    if (this.enableBadgeFieldEditing) {
      return { maxWidth: '40%' }
    }
    return { maxWidth: '100%', justifyContent: 'center' }
  }

  get getFontList () {
    if (this.fontList.length) {
      const customFonts = this.fontList.map(font => font.font_family)
      return this.defaultFonts.concat(customFonts).sort((a, b) => a >= b ? 1 : -1)
    }
    return this.defaultFonts
  }

  get getEmailList () {
    return this.$data.emails_name_array
  }

  get showEventBanner (): boolean {
    const globalSettings = this.$data.settingsForm['Global Settings']
    if (globalSettings) {
      for (let i = 0; i < globalSettings.length; i++) {
        const setting = globalSettings[i]
        if (setting.key === 'display_event_banner') {
          return setting.value === '1'
        }
      }
    }
    return true
  }

  get previewGlobalSettings () {
    const settings: any = {}
    if (this.$data.settingsForm['Global Settings']) {
      this.$data.settingsForm['Global Settings'].forEach(setting => {
        settings[setting.key] = setting.value
      })
    }
    return settings
  }

  get previewPageSettings () {
    const settings: any = {}
    this.$data.previewPages.forEach(page => {
      settings[page] = {}
      if (this.$data.settingsForm[page]) {
        this.$data.settingsForm[page].forEach(setting => {
          settings[page][setting.key] = setting.value
        })
      }
    })
    return settings
  }

  get previewPageStyles () {
    const styles = {}
    this.$data.previewPages.forEach(page => {
      styles[page] = {}
      if (this.previewPageSettings[page]) {
        const pageToken = page.toLowerCase().replace(' ', '')
        styles[page].backgroundColor = this.previewPageSettings[page][`body_background_color_${pageToken}_css`]
        styles[page].backgroundImage = 'url("' + this.previewPageSettings[page][`body_background_image_${pageToken}_css`] + '")'
      }
    })
    return styles
  }

  get previewGlobalStyles () {
    return {
      fontFamily: this.previewGlobalSettings.body_font_global_css,
      fontSize: this.previewGlobalSettings.body_font_size_global_css,
      fontWeight: this.previewGlobalSettings.body_font_weight_global_css,
      color: this.previewGlobalSettings.body_text_color_global_css
    }
  }

  get previewHeaderStyles () {
    return {
      fontFamily: this.previewGlobalSettings.header_font_global_css,
      fontSize: this.previewGlobalSettings.header_font_size_global_css,
      fontWeight: this.previewGlobalSettings.header_font_weight_global_css,
      color: this.previewGlobalSettings.header_color_global_css
    }
  }

  get previewSubheaderStyles () {
    return {
      fontFamily: this.previewGlobalSettings.subheader_font_global_css,
      fontSize: this.previewGlobalSettings.subheader_font_size_global_css,
      fontWeight: this.previewGlobalSettings.subheader_font_weight_global_css,
      color: this.previewGlobalSettings.subheader_color_global_css
    }
  }

  get previewButtonStyles () {
    return {
      fontFamily: this.previewGlobalSettings.buttons_font_global_css,
      fontSize: this.previewGlobalSettings.buttons_font_size_global_css,
      fontWeight: this.previewGlobalSettings.buttons_font_weight_global_css,
      color: this.previewGlobalSettings.buttons_text_color_global_css,
      backgroundColor: this.previewGlobalSettings.buttons_background_color_global_css,
      border: this.previewGlobalSettings.buttons_border_thickness_global_css + ' solid ' + this.previewGlobalSettings.buttons_border_color_global_css,
      borderRadius: this.previewGlobalSettings.buttons_border_radius_global_css
    }
  }

  get previewModalFormStyles () {
    return {
      borderRadius: this.previewGlobalSettings.buttons_border_radius_global_css
    }
  }

  get previewInputStyles () {
    return {
      fontFamily: this.previewGlobalSettings.buttons_font_global_css,
      fontSize: this.previewGlobalSettings.buttons_font_size_global_css,
      border: this.previewGlobalSettings.buttons_border_thickness_global_css + ' solid ' + this.previewGlobalSettings.buttons_border_color_global_css,
      borderRadius: this.previewGlobalSettings.buttons_border_radius_global_css
    }
  }

  /**
   * Return array of URLs to the font CSS files of the fonts used in the specified settings.
   * @returns array of strings
   */
  get fontUrls (): string[] {
    const fontFamilySettings = [
      this.previewGlobalSettings.body_font_global_css,
      this.previewGlobalSettings.buttons_font_global_css,
      this.previewGlobalSettings.header_font_global_css,
      this.previewGlobalSettings.subheader_font_global_css
    ]
    const fontFamilies = fontFamilySettings.filter((fontFamily, index, self) => self.indexOf(fontFamily) === index) // unique values
    return fontFamilies.map(fontFamily => this.getFontUrl(fontFamily))
  }

  get eventBannerUrl () {
    return this.$store.state?.event?.event?.banner
  }

  get activeBadges () {
    return this.badges.filter(badge => badge.active)
  }

  get currentBadgeTemplate (): Record<string, any> {
    return this.activeBadges[this.$data.badgeTemplateIndex]
  }

  get showTemplateOrTemplateWithData () {
    if (this.currentBadgeTemplate) {
      if (this.$data.currentBadgeTemplateDataPreview) {
        return this.getSvgPreviewUnaltered(this.$data.currentBadgeTemplateDataPreview)
      }
      return this.getSvgPreview(this.currentBadgeTemplate.svg)
    }
  }

  get badgeTemplateFields () {
    const fieldAttributeList = this.globalSettings['Global Settings']?.find(item => item.key === 'field_attribute_list')
    const badgeFormFields: any = {}

    if (this.currentBadgeTemplate?.badge_fields && fieldAttributeList?.json_extra_data) {
      const occurrences = this.currentBadgeTemplate.badge_fields.reduce((acc, curr) => {
        acc[curr] ? ++acc[curr] : acc[curr] = 1
        return acc
      }, {})
      fieldAttributeList.json_extra_data.forEach(field => {
        if (this.currentBadgeTemplate.badge_fields.includes(field.name) && fieldAttributeList.value[field.name]?.modifiable) {
          badgeFormFields[field.name] = {
            label: field.label,
            occurrences: occurrences[field.name]
          }
        }
      })
    }

    return badgeFormFields
  }

  data () {
    return {
      emails_name_array: [],
      dataLoaded: false,
      submitting: false,
      settingsForm: {},
      tab: 0,
      previewTab: 0,
      previewPages: ['Page 1', 'Page 2', 'Page 3', 'Page 4'],
      badgeTemplate: null,
      badgeTemplateIndex: 0,
      currentBadgeTemplateDataPreview: null,
      previewShowPage3ConfirmModal: false,
      settingsTab: 0
    }
  }

  async mounted () {
    try {
      this.$data.dataLoaded = false
      this.$store.dispatch('common/showLoader', { value: true })
      const optionGroupsResponse = await this.$store.dispatch('option/getOptionsGroup', {
        event_uuid: this.$route.params.event_uuid
      })
      this.$data.optionGroups = optionGroupsResponse.data ?? []
      this.$data.regTypes = optionGroupsResponse.data.find(optionGroup => optionGroup.name === 'Registration Types')?.options ?? []
      await this.$store.dispatch('media/getFontStores', this.$route.params.uuid)
      this.getSettings()
      await this.getActiveDefaultBadge()
      this.fetchEmails()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.dataLoaded = true
      this.$store.dispatch('common/hideLoader')
    }
  }

  async getSettings () {
    await this.$store.dispatch('badges/getSettings', { event_uuid: this.$route.params.event_uuid })
  }

  async fetchEmails () {
    this.$store.dispatch('email/fetchEmails', this.$route.params.event_uuid)
  }

  multiselectValue (settingValue: string): string[] {
    let valueArray: string[]
    try {
      valueArray = settingValue.split(',')
    } catch (e) {
      valueArray = []
    }
    return valueArray
  }

  isJsonString (str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  @Watch('$vuetify.breakpoint.mdAndDown', { immediate: true })
  onMdAndDownChange (isMdAndDown) {
    this.$store.dispatch('navigation/setModuleLayoutMiniVariant', isMdAndDown)
  }

  @Watch('settings', { immediate: true })
  onSettingsChange (settings) {
    const settingsForm = {}

    // Copy settings into separate object properties so we can display the form in sections
    for (let i = 0; i < settings.length; i++) {
      const setting = settings[i]
      let sectionName = setting.json_extra_data[0].section
      if (settingsForm[sectionName] === undefined) {
        settingsForm[sectionName] = []
      }
      // TODO: feels less than Ideal. Adds complexity. return to later to refactor - ZB
      // parse these two field values to modify settings.
      if (
        setting.key === 'attendee_list_fields' ||
        setting.key === 'field_attribute_list'
      ) {
        sectionName = 'Global Settings'
        setting.value = setting.value && this.isJsonString(setting.value) ? JSON.parse(setting.value) : null

        if (setting.key === 'field_attribute_list') {
          if (setting.value == null) {
            setting.value = JSON.parse('{"first_name":{"required":true,"modifiable":true},"last_name":{"required":true,"modifiable":true},"email":{"required":true,"modifiable":true},"company":{"required":true,"modifiable":true}}')
          }
        }
        if (setting.key === 'attendee_list_fields') {
          if (setting.value == null) {
            setting.value = JSON.parse('["first_name","last_name","email","company"]')
          }
          for (const d of setting.json_extra_data) {
            d.required = (d.required === 'true')
            d.modifiable = (d.modifiable === 'true')
          }
        }
      }

      settingsForm[sectionName].push({
        uuid: setting.uuid,
        key: setting.key,
        value: setting.json_extra_data[0]?.type === 'multiselect' ? this.multiselectValue(setting.value) : setting.value,
        label: setting.label,
        json_extra_data: setting.json_extra_data
      })
    }

    // Sort form sections alphabetically
    this.$data.settingsForm = Object.keys(settingsForm).sort().reduce(
      (obj, key) => {
        obj[key] = settingsForm[key]
        return obj
      },
      {}
    )
  }

  @Watch('emails', { immediate: true })
  onEmailsValueChange (newVal: any) {
    newVal.forEach((email: any) => {
      const name = this.handleEmailName(email.key)
      this.$data.emails_name_array.push({
        text: name,
        value: email.key
      })
    })
  }

  @Watch('settingsForm', { deep: true, immediate: true })
  onSettingsFormChange (settingsForm) {
    const page3Settings = settingsForm['Page 3']
    if (Array.isArray(page3Settings)) {
      page3Settings.forEach(setting => {
        if ((setting.key === 'display_confirm_block1_page3' || setting.key === 'display_confirm_block2_page3' || setting.key === 'display_confirm_block3_page3') && setting.value === '1') {
          this.$data.previewShowPage3ConfirmModal = true
        }
      })
    }
  }

  // Return items for select field, either from a function call or the values in the setting
  selectItems (setting: BadgesSetting): string[] {
    if (setting.json_extra_data[0].value_function) {
      return this[setting.json_extra_data[0].value_function]
    }
    return setting.json_extra_data[0].values
  }

  /**
   * Return the font file URL if it is a custom font; otherwise return the Google fonts URL.
   * @param {string} fontName
   * @returns string
   */
  getFontUrl (fontName: string): string {
    if (typeof fontName === 'string') {
      const customFonts = this.fontList.map(font => font.font_family)
      const i = customFonts.indexOf(fontName)
      if (i >= 0) {
        return this.fontList[i]?.files?.css
      } else {
        return `https://fonts.googleapis.com/css2?family=${fontName.replace(/ /g, '+')}:ital,wght@0,400;0,700;1,400;1,700&display=swap`
      }
    }
    return ''
  }

  async getActiveDefaultBadge () {
    if (this.activeBadges?.length) {
      for (let i = 0; i < this.activeBadges.length; i++) {
        if (this.activeBadges[i].registration_type === '_default') {
          this.$data.badgeTemplate = this.activeBadges[i]
          this.$data.badgeTemplateIndex = i
        }
      }
    }
    await this.getBadgeTemplateWithData()
  }

  async cycleBadgeTemplate () {
    if (this.$data.badgeTemplateIndex === this.activeBadges.length - 1) {
      this.$data.badgeTemplateIndex = 0
    } else {
      this.$data.badgeTemplateIndex++
    }
    await this.getBadgeTemplateWithData()
  }

  async getBadgeTemplateWithData () {
    if (this.currentBadgeTemplate) {
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        reg_type: this.currentBadgeTemplate.registration_type
      }
      const rando = await this.$store.dispatch('attendee/fetchRandomAttendee', payload)
      if (!rando || !rando.participant_data || !rando.selected_options) {
        this.$data.currentBadgeTemplateDataPreview = null
      } else {
        this.$data.currentBadgeTemplateDataPreview = this.buildDataPreview(this.currentBadgeTemplate.svg, rando.participant_data, rando.selected_options)
      }
    }
  }

  isUUID (val: string) {
    return val.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')
  }

  isOptionGroup (fieldName: string): boolean {
    return this.$data.optionGroups?.some(optionGroup => optionGroup.uuid === fieldName) ?? false
  }

  submit () {
    this.$data.submitting = true
    // Put all settings back into a single array for submission
    let payload: BadgesSetting[] = []
    for (const section in this.$data.settingsForm) {
      // TODO: return to this to find a better way.
      if (section === 'Global Settings') {
        for (const s of this.$data.settingsForm[section]) {
          if ( // skip all of settings that aren't these two.
            s.key !== 'attendee_list_fields' ||
            s.key !== 'field_attribute_list'
          ) continue
          s.value = JSON.stringify(s.value) // stringify data.
        }
      }
      payload = payload.concat(this.$data.settingsForm[section])
    }
    this.updateSettings(payload)
  }

  async uploadFile (file: File) {
    try {
      const response = await this.$store.dispatch('media/uploadMedia', {
        event_uuid: this.$route.params.event_uuid,
        fileData: file,
        fetch: false
      })
      return response.responseEventMedia.data.url
    } catch (error) {
      Container.get(ErrorHandlerService).error('Error uploading file: ' + file.name)
      throw new Error('Settings not saved')
    }
  }

  async updateSettings (payload: BadgesSetting[]) {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      for (let i = 0; i < payload.length; i++) {
        const setting = payload[i]
        if (setting.json_extra_data[0].type === 'fileupload' && setting.value instanceof File) {
          setting.value = await this.uploadFile(setting.value as File)
        } else if (setting.json_extra_data[0].type === 'multiselect' && Array.isArray(setting.value)) {
          setting.value = setting.value?.join(',')
        }
      }
      await this.$store.dispatch('badges/updateSettings', { event_uuid: this.$route.params.event_uuid, data: payload })
      Container.get(Notification).success('Settings saved')
      await this.getSettings()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.submitting = false
      this.$store.dispatch('common/hideLoader')
    }
  }
}
